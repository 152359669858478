import axios from "axios";

export const resultTypes = {
  over05: 'Over 0.5',
  over15: 'Over 1.5',
  over25: 'Over 2.5',
  over35: 'Over 3.5',
  under05: 'Under 0.5',
  under15: 'Under 1.5',
  under25: 'Under 2.5',
  under35: 'Under 3.5',
}

export interface Result {
  type: keyof typeof resultTypes;
}

export type Strategy = {
  id: string | null,
  name: string,
  type: string
  active: boolean
  strategy: Result[]
}

const host = "https://placar.iabetsoficial.com.br"

export const getStrategies = async () => {
  console.log("Opa")
  const strategies = await axios.get(`${host}/api/strategies`)

  return strategies.data as Strategy[]
}

export const addStrategy = async (strategy: Strategy) => {
  if (strategy.id)
    await axios.put(`${host}/api/strategies/${strategy.id}`, strategy)
  else
    await axios.post(`${host}/api/strategies`, strategy)
  window.location.reload();
}

export const deleteStrategy = async (id: string) => {
  await axios.delete(`${host}/api/strategies/${id}`)
  window.location.reload();
}

export const tableColumns = [
  { key: "name", sortable: true, label: "Nome" },
  { key: "type", sortable: true, label: "Tipo" },
  { key: "active", sortable: true, label: "Ativo" },
  { key: "actions", sortable: true, label: "Ações" },
]