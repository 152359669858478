


































































































































import {
  BTable,
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BFormCheckbox,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownDivider,
} from "bootstrap-vue"
import {
  defineComponent,
  onMounted,
  reactive,
  Ref,
  ref,
} from "@vue/composition-api"

import type { Strategy } from "./useStrategies"
import {
  resultTypes,
  addStrategy,
  getStrategies,
  tableColumns,
  deleteStrategy,
} from "./useStrategies"

export default defineComponent({
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BForm,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BDropdownDivider,
  } as any,
  setup(props, context) {
    const modalStrategy = ref(null) as Ref<any>
    const strategies = ref<Strategy[]>()

    const modalData = reactive({
      id: null,
      name: "",
      type: "",
      active: true,
      strategy: [],
    } as Strategy)

    onMounted(async () => {
      strategies.value = await getStrategies()
    })

    const openAddStrategyModal = () => {
      modalData.id = null
      modalData.active = true
      modalData.name = ""
      modalData.type = ""
      modalData.strategy = []
      modalStrategy.value.show()
    }

    const modalAddResult = () => {
      modalData.strategy.push({
        type: "over05",
      })
    }

    const edit = (id: string) => {
      const strategyToEdit = strategies.value?.find(
        (strategy) => strategy.id == id
      )
      if (!strategyToEdit) return

      console.log(strategyToEdit)
      modalData.id = id
      modalData.name = strategyToEdit.name
      modalData.type = strategyToEdit.type
      modalData.strategy = strategyToEdit.strategy
      modalData.active = strategyToEdit.active ? true : false
      modalStrategy.value.show()
    }

    return {
      strategies,
      modalData,
      modalStrategy,
      openAddStrategyModal,
      modalAddResult,
      resultTypes,
      addStrategy,
      tableColumns,
      deleteStrategy,
      edit,
    }
  },
})
